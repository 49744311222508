<template>
  <b-row class="form-box">
    <b-col md="12">
      <b-nav tabs class="form-section">
        <b-nav-item disabled>Select Purchase</b-nav-item>
      </b-nav>
      <b-col md="12" class="mt-1 mb-4" v-if="this.initData">
        <b-form-group
          label-for="purchase_overview_id"
          label-cols-sm="2"
          label-cols-lg="2"
          label-align-sm="right"
          label-size="sm"
          label="Purchase"
        >
          <sv-select
            @search="onSearch"
            @input="updateForm($event)"
            class="select-mh-9"
            v-model="purchase_overview_id"
            placeholder="Please Type to Select"
            :options="options.purchase_overview_id"
            multiple
          >
            <template slot="selected-option" slot-scope="option">
              {{ option.system_id }}
            </template>
          </sv-select>
        </b-form-group>
      </b-col>
      <b-col md="12" class="mt-1 mb-4">
        <b-form-group
          label-for="enter_mode"
          label-cols-sm="2"
          label-cols-lg="2"
          label-align-sm="right"
          label-size="sm"
          label="Enter Mode"
        >
          <b-form-radio-group
            v-model="enter_mode"
            id="enter_mode"
            :options="options.enter_mode"
            :name="'enter_mode'"
            @change="switchEnter($event)"
            text-field="label"
          ></b-form-radio-group>
        </b-form-group>
      </b-col>
      <b-overlay :show="loading" style="min-height: 150px">
        <b-table striped hover :items="items" :fields="fields" :id="tableId">
          <template v-slot:cell(net_amount)="row">
            <b-form-input
              v-model="row.item.net_amount"
              type="text"
              :placeholder="$t('page.please_choose_input')"
              :disabled="row.item.net_amount_disabled"
              @change="inputNetAmount($event, row.item)"
            ></b-form-input>
          </template>
          <template v-slot:cell(gross_amount)="row">
            <b-form-input
              v-model="row.item.gross_amount"
              type="text"
              :placeholder="$t('page.please_choose_input')"
              :disabled="row.item.gross_amount_disabled"
              @change="inputGrossAmount($event, row.item)"
            ></b-form-input>
          </template>
          <template v-slot:cell(tax_rate)="row">
            <sv-select
              style="width: 100px"
              v-model="row.item.tax_rate"
              :placeholder="$t('page.please_choose')"
              :options="options.taxRate"
              :reduce="options => options.value"
              :clearable="false"
              id="last_comment_level"
              label="label"
              @input="inputTaxRate($event, row.item)"
            >
            </sv-select>
          </template>
          <template v-slot:cell(product_qty)="row">
            <b-form-input
              v-model="row.item.product_qty"
              type="text"
              :placeholder="$t('page.please_choose_input')"
              @change="inputQty($event, row.item)"
            ></b-form-input>
          </template>
        </b-table>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import _ from 'lodash';
import apiBus from '@/common/apiBus/index';
import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';
export default {
  name: 'uploadInvoiceSelectPurchase',

  data() {
    return {
      id: null,
      purchase_overview_id: this.initData.select,
      enter_mode: 1,
      tableId: '1',
      items: [],
      loading: false,
      statusData: this.initData, // 重新定义数据
      fields: [
        {
          key: 'purchase',
          label: 'Purchase'
        },
        {
          key: 'net_amount',
          label: 'Unit Price Net'
        },
        {
          key: 'gross_amount',
          label: 'Unit Price Gross '
        },
        {
          key: 'vat_amount',
          label: 'Unit VAT Amount'
        },
        {
          key: 'tax_rate',
          label: 'Tax Rate'
        },
        {
          key: 'product_qty',
          label: 'Quantity'
        },
        {
          key: 'total_net_amount',
          label: 'Total Net Amount'
        },
        {
          key: 'total_gross_amount',
          label: 'Total Gross Amount'
        },
        {
          key: 'total_vat_amount',
          label: 'Total VAT Amount'
        },
        {
          key: 'total_freight',
          label: 'Total Freight'
        }
      ],
      options: {
        purchase_overview_id: this.initData.select || [],
        enter_mode: [
          { label: 'Net', value: 0 },
          { label: 'Gross', value: 1 }
        ],
        taxRate: [
          { label: '0%', value: 0 },
          { label: '7%', value: 0.07 },
          { label: '19%', value: 0.19 }
        ]
      }
    };
  },
  props: {
    initData: Object,
    unitFreightNet: String
  },
  inheritAttrs: false,
  components: {},
  methods: {
    // 欧元转数字
    turnNumbers(i) {
      let data;
      switch (typeof i) {
        case 'string':
          data = i.replace('.', '').replace(',', '.');
          break;
        case 'number':
          data = i;
          break;
      }
      return data;
    },

    // 数字转欧元
    turnDollar(num) {
      var p = num.toFixed(4).split('.');
      var chars = p[0].split('').reverse();
      var newstr = '';
      var count = 0;
      for (let x in chars) {
        count++;
        if (count % 3 == 1 && count != 1) {
          newstr = chars[x] + '.' + newstr;
        } else {
          newstr = chars[x] + newstr;
        }
      }
      return newstr + ',' + p[1];
    },

    onSearch: function(search, loading) {
      if (search != null && search != '') {
        loading(true);
        this.search(loading, search, this);
      }
    },
    // 做放抖动处理
    search: _.debounce((loading, search, vm) => {
      apiBus.uploadInvoice
        .purchaseInvoiceSupplierList({ keyWord: search })
        .then(data => {
          // console.log(data);
          vm.options.purchase_overview_id = data.data.data;
          loading(false);
        })
        .catch(error => {
          console.log(error);
          vm.options.purchase_overview_id = [];
          loading(false);
        });
    }, 350),

    addTr(item) {
      console.log(item);
      this.loading = true;
      let arr = [],
        itemArr = [];
      this.purchase_overview_id.forEach(item => {
        console.log(item);
        arr.push(item.value);
      });
      apiBus.uploadInvoice
        .getPurchaseInvoiceInfo({
          purchase_overview_id: arr,
          id: this.$route.params.id
        })
        .then(data => {
          data.data.data.forEach((item, index) => {
            itemArr.push({
              purchase: this.options.purchase_overview_id[index].label,
              net_amount: item.net_amount,
              net_amount_disabled: this.enter_mode == 0 ? false : true,
              gross_amount: item.gross_amount,
              gross_amount_disabled: this.enter_mode == 0 ? true : false,
              vat_amount: item.vat_amount,
              tax_rate: parseFloat(item.tax_rate),
              product_qty: item.product_qty,
              total_net_amount: this.turnDollar(
                this.turnNumbers(item.net_amount) * item.product_qty
              ),
              total_gross_amount: this.turnDollar(
                this.turnNumbers(item.gross_amount) * item.product_qty
              ),
              total_vat_amount: this.turnDollar(
                this.turnNumbers(item.vat_amount) * item.product_qty
              ),
              total_freight: this.turnDollar(
                this.turnNumbers(this.unitFreightNet) * item.product_qty
              ),
              purchase_overview_id: this.options.purchase_overview_id[index]
                .value
            });
          });
          this.items = itemArr;
          this.$emit('updateTable', this.items);
          this.loading = false;
        })
        .catch(error => {
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 9999999999, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
          this.items = [];
          this.loading = false;
          console.log(error);
        });
    },

    removeTr(index) {
      console.log(index);
      this.items.splice(index, 1);
      this.$emit('updateTable', this.items);
    },

    updateForm(e) {
      if (e.length > this.items.length) {
        console.log('添加操作');
        try {
          e.forEach((item, index) => {
            console.log(this.items[index]);
            if (this.items.length == 0 || this.items[index] == undefined) {
              this.addTr(item);
              throw new Error('add');
            }
          });
        } catch (e) {
          console.log(e.message);
        }
      } else {
        console.log('删除操作');
        try {
          this.items.forEach((item, index) => {
            let ifId = true;
            e.forEach(x => {
              if (item.purchase_overview_id == x.value) {
                ifId = false;
              }
            });
            if (ifId) {
              // console.log(index);
              this.removeTr(index);
              throw new Error('remove');
            }
          });
        } catch (e) {
          console.log(e.message);
        }
      }
    },

    switchEnter(e) {
      switch (e) {
        case 0:
          this.items.forEach(item => {
            item.gross_amount_disabled = true;
            item.net_amount_disabled = false;
          });
          this.$emit('updateTable', this.items);
          break;
        case 1:
          this.items.forEach(item => {
            item.gross_amount_disabled = false;
            item.net_amount_disabled = true;
          });
          this.$emit('updateTable', this.items);
          break;
      }
    },

    // Select Purchase 里 Unit Price Net 计算
    inputNetAmount(e, item) {
      let data = this.turnNumbers(e) * (1 + item.tax_rate);
      item.gross_amount = this.turnDollar(data);
      item.vat_amount = this.turnDollar(this.turnNumbers(e) * item.tax_rate);
      item.total_net_amount = this.turnDollar(
        this.turnNumbers(e) * item.product_qty
      );
      item.total_gross_amount = this.turnDollar(data * item.product_qty);
      item.total_vat_amount = this.turnDollar(
        e * item.tax_rate * item.product_qty
      );
      this.$emit('updateTable', this.items);
    },

    // Select Purchase 里 Unit Price Gross 计算
    inputGrossAmount(e, item) {
      let data = this.turnNumbers(e) / (1 + item.tax_rate);
      item.net_amount = this.turnDollar(data);
      item.vat_amount = this.turnDollar(data * item.tax_rate);
      item.total_net_amount = this.turnDollar(data * item.product_qty);
      item.total_gross_amount = this.turnDollar(
        this.turnNumbers(e) * item.product_qty
      );
      item.total_vat_amount = this.turnDollar(
        data * item.tax_rate * item.product_qty
      );
      this.$emit('updateTable', this.items);
    },

    // Select Purchase 里 Tax Rate 计算
    inputTaxRate(e, item) {
      console.log(item);
      let data;
      switch (this.enter_mode) {
        case 0:
          data = this.turnNumbers(item.net_amount) * (1 + this.turnNumbers(e));
          item.gross_amount = this.turnDollar(data);
          item.vat_amount = this.turnDollar(
            this.turnNumbers(item.net_amount) * this.turnNumbers(e)
          );
          item.total_gross_amount = this.turnDollar(data * item.product_qty);
          item.total_vat_amount = this.turnDollar(
            this.turnNumbers(item.net_amount) *
              this.turnNumbers(e) *
              item.product_qty
          );
          this.$emit('updateTable', this.items);
          break;
        case 1:
          data =
            this.turnNumbers(item.gross_amount) / (1 + this.turnNumbers(e));
          item.net_amount = this.turnDollar(data);
          item.vat_amount = this.turnDollar(data * this.turnNumbers(e));
          item.total_net_amount = this.turnDollar(data * item.product_qty);
          item.total_vat_amount = this.turnDollar(data * e * item.product_qty);
          this.$emit('updateTable', this.items);
          break;
      }
    },

    inputQty(e, item) {
      item.total_net_amount = this.turnDollar(
        this.turnNumbers(e) * this.turnNumbers(item.net_amount)
      );
      item.total_gross_amount = this.turnDollar(
        this.turnNumbers(e) * this.turnNumbers(item.gross_amount)
      );
      item.total_vat_amount = this.turnDollar(
        this.turnNumbers(e) * this.turnNumbers(item.vat_amount)
      );
      item.total_freight = this.turnDollar(
        this.turnNumbers(this.unitFreightNet) * this.turnNumbers(e)
      );
      this.$emit('updateTable', this.items);
    },

    initialTime(obj, name) {
      console.log(obj, name);
      obj[name] = null;
    },

    init() {
      let data = [];
      this.statusData.purchase_invoice.forEach((item, index) => {
        data.push({
          purchase: this.options.purchase_overview_id[index].label,
          net_amount: item.net_amount,
          net_amount_disabled: true,
          gross_amount: item.gross_amount,
          gross_amount_disabled: false,
          vat_amount: item.vat_amount,
          tax_rate: item.tax_rate,
          product_qty: item.product_qty,
          total_net_amount: this.turnDollar(
            this.turnNumbers(item.net_amount) * item.product_qty
          ),
          total_gross_amount: this.turnDollar(
            this.turnNumbers(item.gross_amount) * item.product_qty
          ),
          total_vat_amount: this.turnDollar(
            this.turnNumbers(item.vat_amount) * item.product_qty
          ),
          total_freight: this.turnDollar(
            this.turnNumbers(this.unitFreightNet) * item.product_qty
          ),
          purchase_overview_id: this.options.purchase_overview_id[index].value
        });
      });
      this.items = data;
    }
  },

  computed: {},
  mounted() {
    this.init();
  },
  watch: {
    unitFreightNet(n) {
      console.log(n);
      this.items.forEach(item => {
        item.total_freight = this.turnDollar(
          this.turnNumbers(n) * this.turnNumbers(item.product_qty)
        );
      });
    }
  }
};
</script>
<style scoped></style>
